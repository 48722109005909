import React, { useState } from "react";
// Files Import
import CommonCard from "../commonCard/CommonCard";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH5Heading from "../../common/components/styledh5heading/StyledH5Heading";
const ChangePayoutCard = ({
  item,
  isEditable,
  onRewardChange,
  onGoalsChange,
}) => {
  const { id, image, name, goal, reward } = item;
  const inputClassName =
    "bg-[#F9F9F9] text-black text-md w-full rounded-[30px] py-3 pl-6 outline-none";
  const [rewardState, setRewardState] = useState(reward);
  const [minimumGoal, setMinimumGoal] = useState(goal.minimum);
  const [maximumGoal, setMaximumGoal] = useState(goal.maximum);
  const handleRewardChange = (e) => {
    const newReward = e.target.value;
    setRewardState(newReward);
    onRewardChange(id, newReward);
  };
  const handleMinimumGoalChange = (e) => {
    const newMinimumGoal = e.target.value;
    setMinimumGoal(newMinimumGoal);
    onGoalsChange(id, { ...goal, minimum: newMinimumGoal });
  };
  const handleMaximumGoalChange = (e) => {
    const newMaximumGoal = e.target.value;
    setMaximumGoal(newMaximumGoal);
    onGoalsChange(id, { ...goal, maximum: newMaximumGoal });
  };
  return (

    <CommonCard
      width={
        "hover:shadow-lg transition-all duration-100 h-full self-stretch w-full"
      }
    >
      <div className="w-full flex flex-col justify-center items-center px-10 sm:px-5 md:px-10 py-4">
        <div className="w-full h-full flex flex-col justify-center items-center gap-6 border-b-2 border-[#F9F9F9] py-6">
          <img
            className="h-[50px] w-[50px]"
            src={`${process.env.REACT_APP_SERVER_ROOT}/static/icons/${image}`}
            alt={name}
          />
          <StyledH5Heading fontColor="text-black">
            {name ? name : "--"}
          </StyledH5Heading>
        </div>
        <div className="w-full py-4">
          <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
            <StyledMdText fontColor="text-black font-semibold">
              Reward
            </StyledMdText>
            <input
              className={inputClassName}
              type="text"
              value={rewardState ?? 0}
              onChange={handleRewardChange}
              readOnly={!isEditable}
            />
          </div>
          <div className="w-full flex flex-col justify-center items-start gap-2">
            <StyledMdText fontColor="text-black font-semibold">
              {id === "weight"
                ? "Goals (BMI)"
                : id === "bodyTemperature"
                ? "Goals (C°)"
                : "Goals"}
            </StyledMdText>
            <div className="w-full flex justify-start items-center gap-2">
              {id === "steps" ? (
                <input
                  className={inputClassName}
                  type="text"
                  value={minimumGoal ?? 0}
                  onChange={handleMinimumGoalChange}
                  readOnly={!isEditable}
                />
              ) : id === "sleep" ? (
                <div className="w-full flex flex-col justify-center items-center gap-2">
                  <input
                    className={inputClassName}
                    type="text"
                    value={minimumGoal ?? 0}
                    onChange={handleMinimumGoalChange}
                    readOnly={!isEditable}
                  />
                  <div className="w-full flex justify-center items-center gap-2">
                    <div className="w-full flex flex-col justify-center items-start">
                      <StyledMdText fontColor="text-black  font-semibold">
                        REM (%)
                      </StyledMdText>

                      <input
                        className={inputClassName}
                        type="text"
                        value={minimumGoal ?? 0}
                        onChange={handleMinimumGoalChange}
                        readOnly={!isEditable}
                      />
                    </div>
                    <div className="w-full flex flex-col justify-center items-start">
                      <StyledMdText fontColor="text-black font-semibold">
                        Deep (%)
                      </StyledMdText>
                      <input
                        className={inputClassName}
                        type="text"
                        value={minimumGoal ?? 0}
                        onChange={handleMinimumGoalChange}
                        readOnly={!isEditable}
                      />{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full flex justify-center items-center gap-2">
                  <input
                    className={inputClassName}
                    type="text"
                    value={minimumGoal ?? 0}
                    onChange={handleMinimumGoalChange}
                    readOnly={!isEditable}
                  />
                  <StyledMdText fontColor="text-light-gray">To</StyledMdText>
                  <input
                    className={inputClassName}
                    type="text"
                    value={maximumGoal ?? 0}
                    onChange={handleMaximumGoalChange}
                    readOnly={!isEditable}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonCard>
  );
};
export default ChangePayoutCard;
