import React from "react";
import TokenSection from "../../sections/tokenSection/TokenSection";
const TokensPage = () => {
  return (
    <div>
      <TokenSection />
    </div>
  );
};

export default TokensPage;
