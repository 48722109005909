import { Navigate, useRoutes } from "react-router-dom";
import ForgotPassPage from "./pages/forgotPassPage/ForgotPassPage";
import LoginPage from "./pages/loginPage/LoginPage";
import OTPPage from "./pages/otpPage/OTPPage";
import NewPassPage from "./pages/newPassPage/NewPassPage";
import TokensPage from "./pages/tokensPage/TokensPage";
import BlogsPage from "./pages/blogsPage/BlogsPage";
import SimpleLayout from "./components/layout/simpleLayout/SimpleLayout";
import DashboardLayout from "./components/layout/dashBoardLayout/DashBoardLayout";
import DashboardPage from "./pages/dashBoardPage/DashboardPage";
import UserDetailsPage from "./pages/userDetailsPage/UserDetailsPage";
import ProfilePage from "./pages/profilePage/ProfilePage";
import BlogsLayout from "./components/layout/blogsLayout/BlogsLayout";
import NewBlog from "./pages/newBlog/NewBlog";
import EditBlog from "./pages/editBlog/EditBlog";
import BlogsCategoriesPage from "./pages/BlogsCategoriesPage/BlogsCategoriesPage";
import SingleUserPage from "./pages/singleUserPage/SingleUserPage";
import SingleBlogSection from "./sections/singleBlogSection/singleBlogSection";
import ChangePayoutsPage from "./pages/ChangePayoutsPage/ChangePayoutsPage";

export const Router = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/dashboard" />,
          index: true,
        },
        {
          path: "dashboard",
          element: <DashboardPage />,
        },
        {
          path: "user-details",
          element: <UserDetailsPage />,
        },
        {
          path: "/user-details/:id",
          element: <SingleUserPage />,
        },
        {
          path: "blogs-categories",
          element: <BlogsCategoriesPage />,
        },
        {
          path: "change-payouts",
          element: <ChangePayoutsPage />,
        },
        {
          path: "tokens",
          element: <TokensPage />,
        },
        {
          path: "/",
          element: <BlogsLayout />,
          children: [
            {
              element: <Navigate to="/blogs" />,
              index: true,
            },
            {
              path: "blogs",
              element: <BlogsPage />,
            },
            {
              path: "add-new-blog",
              element: <NewBlog />,
            },
            {
              path: "/single-blog/:id",
              element: <SingleBlogSection />,
            },
            {
              path: "/edit-blog/:blogId",
              element: <EditBlog />,
            },
          ],
        },
        {
          path: "profile",
          element: <ProfilePage />,
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassPage />,
        },
        {
          path: "verify-email",
          element: <OTPPage />,
        },
        {
          path: "reset-password",
          element: <NewPassPage />,
        },

      ],
    },
  ]);

  return routes;
};
