import React from "react";

const DropDown = ({ label, options, value, onChange }) => {
  return (
    <div className="w-full">
      {label && <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>}
      <div className="custom-select-wrapper">
        <select
          label={label}
          value={value}
          onChange={onChange}
          className="custom-select w-full text-h6 h-14 px-4 text-start text-black rounded-xl border-[0.5px] border-light-gray outline-none cursor-pointer"
        >
          <option value="" disabled>Select a category</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value} className="cursor-pointer">
              {option.label}
            </option>
          ))}
        </select>
        <div className="select-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
