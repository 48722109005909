import React from "react";

const StyledMdText = ({ children, fontColor, fontColor2 }) => {
  return (
    <p className={`text-md font-inter ${fontColor}`}>
      {children}
      <span className={`${fontColor2}`}></span>
    </p>
  );
};

export default StyledMdText;
