import React, { useState, useEffect } from "react";

// Files Import
import { content } from "../../data/data";
import Table from "../../components/Table/Table";
import SearchField from "../../components/SearchField/SearchField";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";
import BlogCategoryModal from "../../components/blogCategoryModal/BlogCategoryModal";
import { callApi } from "../../API/api";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import CommonModal from "../../components/commonModal/CommonModal";
import { toast } from "react-toastify";
import { useStateContext } from "../../context/StateContext";

const BlogsCategoriesSection = () => {
  const [meta, setMeta] = useState({
    total: 0,
    totalPages: 0,
    page: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { isLoading, setIsLoading, categories, setCategories, setIsSubmitting,
    editCategoryItem, setEditCategoryItem, addCategoryItem, setAddCategoryItem, isCategoryModalOpen,
    setIsCategoryModalOpen,
    deleteModalOpen, setDeleteModalOpen } = useStateContext();
  const itemsPerPage = 5;

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await callApi("blogs/get-all-categories", "GET", null, {
        page: currentPage + 1,
        perPage: itemsPerPage
      });
      if (response.success) {
        setCategories(response.data);
        setMeta(response.meta);
      } else {
        toast.error("Failed to fetch categories", response.message);
      }
    } catch (error) {
      toast.error("Error fetching categories", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [currentPage]);

  const updateCategory = async () => {
    try {
      setIsSubmitting(true);
      const response = await callApi(`/blogs/update-category`, "POST", {
        id: editCategoryItem?._id,
        isActive: editCategoryItem?.isActive,
        ...editCategoryItem,
      });
      if (response.success) {
        toast.success("Category updated successfully");
        fetchCategories();
        handleCloseModal();
        setEditCategoryItem(null);
      } else {
        toast.error("Failed to update category", response.message);
      }
    } catch (error) {
      toast.error("Error updating category", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddCategory = async () => {
    try {
      setIsSubmitting(true)
      const payload = {
        ...addCategoryItem,
        isActive: addCategoryItem?.isActive,
        id: addCategoryItem?._id,
      };

      const response = await callApi("blogs/create-category", "POST", payload);
      if (response.success) {
        toast.success(response.message);
        handleCloseModal();
        setAddCategoryItem(null);
        fetchCategories();
      } else {
        toast.error("Failed operation: " + response.message);
      }
    } catch (error) {
      toast.error(
        "Error: " + (error?.response?.data?.message ?? error.message)
      );
    } finally {
      setIsSubmitting(false)
    }
  };

  const deleteCategory = async () => {
    try {
      setIsSubmitting(true);
      setIsLoading(true);
      const response = await callApi(`/blogs/delete-category`, "POST", {
        id: deleteModalOpen?._id,
      });
      setIsLoading(false);
      if (response.success) {
        fetchCategories();
        setDeleteModalOpen(null);
        handleCloseModal();
        toast.success(response?.message ?? "Category deleted successfully");
      } else {
        toast.error("Failed to delete category", response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error: " + error.response?.data?.message ?? error?.message);
    } finally {
      setIsSubmitting(false)
    }
  };

  const filteredData = categories.filter((category) =>
    Object.values(category).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCloseModal = () => {
    setIsCategoryModalOpen(false);
    setSelectedCategory("");
  };

  const handleOpenModal = (category) => {
    setSelectedCategory(category);
    setIsCategoryModalOpen(true);
  };

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div
      className="flex flex-col w-full gap-5"
      onClick={(e) => handleBackdropClick(e)}
    >
      {/* Heading */}
      <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center">
        <div>
          <StyledH3Heading
            children={"Blogs Categories"}
            fontColor={"text-black pl-5"}
          />
        </div>
        <div className="flex flex-col items-end w-full gap-2 md:flex-row md:items-center md:gap-5 md:w-fit">
          <StyledMdText
            fontColor={"text-light-gray text-nowrap"}
            children={`Total Categories: ${meta?.total || "please wait.."}`}
          />
          <div className="w-full lg:w-[349px]">
            <SearchField
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end gap-4">
        {/* ADD NEW BLOG */}
        <div
          onClick={() => handleOpenModal("add")}
          className="flex items-center justify-end gap-2 cursor-pointer w-fit"
        >
          <img src="/assets/blog/add_circle.svg" alt="Add Circle" />
          <StyledH6Heading fontColor={"text-yellow"} children={"Add New"} />
        </div>
        {/* User Table */}
        {isLoading ? (
          <SkeletonLoading type="table" />
        ) : (
          <Table
            custom={true}
            pageCount={meta.totalPages}
            currentPage={currentPage}
            isBlogCategory={true}
            currentItems={filteredData}
            handlePageClick={handlePageClick}
            head={content.tablesHeadData.blogsCategories}
          />
        )}
      </div>
      {isCategoryModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <BlogCategoryModal
            onClose={handleCloseModal}
            fetchCategories={fetchCategories}
            updateCategory={updateCategory}
            title={
              selectedCategory ? "Add Blog Category" : "Edit Blog Category"
            }
            mode={selectedCategory ? "add" : "edit"}
            description="Are you sure you want to delete this blog post?"
            label="Cancel"
            fontColor="text-light-gray"
            label2={selectedCategory ? "Add" : "Save"}
            fontColor2="text-black"
            add={selectedCategory}
            handleAction={selectedCategory ? handleAddCategory : updateCategory}
          />
        </div>
      )}
      {!!deleteModalOpen && (
        <CommonModal
          title={`Delete ${deleteModalOpen?.name}`}
          description="Are you sure you want to delete this category?"
          label="Cancel"
          fontColor="text-light-gray"
          label2={"Confirm"}
          fontColor2="text-black"
          onClose={() => setDeleteModalOpen(null)}
          onConfirm={deleteCategory}
        />
      )}
    </div>
  );
};

export default BlogsCategoriesSection;
