import React from "react";
import CommonCard from "../../../components/commonCard/CommonCard";
import StyledMdText from "../styledMdText/styledMdText";
import StyledH6Heading from "../styledh6heading/StyledH6Heading";
import TextSkeleton from "../../../components/textSkeleton/TextSkeleton";
import SkeletonLoading from "../../../components/skeleton/SkeletonLoading";

const TopHolders = ({ topHolders, isLoading }) => {

  return (
    <CommonCard>
      <div className="flex flex-col gap-5 pt-5 pb-10 px-7">
        <div className="translate-x-[-10px]">
          <StyledH6Heading fontColor={"black"} children={"Top Holders"} />
        </div>
        {isLoading ?
          <SkeletonLoading type="topHolders" /> :
          topHolders?.map((holder, index) => (
            <div key={index}>
              <div className="flex flex-col gap-6">
                <div className="flex items-center justify-between pt-1.5">
                  <div className="flex items-center gap-2">
                    <img src="/assets/icons/wallet-icon.svg" alt="Wallet" />
                    <StyledMdText fontColor={"black"} children={holder.address ? (`${holder.address.slice(0, 4)}......${holder.address.slice(-4)}` || <TextSkeleton width="w-[20px]" />) : '--'} />
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/assets/icons/coins-icon.svg" alt="Token" />
                    <StyledMdText fontColor={"black"} children={holder.holdings ? (`${holder.holdings} FITS` || <TextSkeleton width="w-[10px]" />) : "0 FITS"} />
                  </div>
                </div>
                {index < topHolders.length - 1 && <hr />}
              </div>
            </div>
          ))}
      </div>
    </CommonCard>
  );
};

export default TopHolders;
