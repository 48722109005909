import React from "react";
import ChangePayouts from "../../sections/ChangePayoutsSection/ChangePayouts";

const ChangePayoutsPage = () => {
  return (
    <div>
      <ChangePayouts />
    </div>
  );
};

export default ChangePayoutsPage;
