import React, { createContext, useState, useContext } from "react";

const StateContext = createContext();

export const useStateContext = () => useContext(StateContext);

export const StateContextProvider = ({ children }) => {

  const [blogItem, setBlogItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blogModalOpen, setBlogModalOpen] = useState(false);
  const [addCategoryItem, setAddCategoryItem] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [editCategoryItem, setEditCategoryItem] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  return (
    <StateContext.Provider
      value={{
        blogItem,
        isLoading,
        categories,
        isModalOpen,
        isSubmitting,
        blogModalOpen,
        addCategoryItem,
        deleteModalOpen,
        editCategoryItem,
        isCategoryModalOpen,
        setBlogItem,
        setIsLoading,
        setCategories,
        setIsModalOpen,
        setIsSubmitting,
        setBlogModalOpen,
        setAddCategoryItem,
        setDeleteModalOpen,
        setEditCategoryItem,
        setIsCategoryModalOpen,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
