import React from "react";
import UserDetailsSection from "../../sections/userDetaisSection/UserDetailsSection"

const UserDetailsPage = () => {
  return (
    <div>
      <UserDetailsSection />
    </div>
  );
};

export default UserDetailsPage;
