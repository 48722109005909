import React from "react";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";

const Button = ({
  label,
  onClick,
  variant,
  fontColor,
  className,
  ...props
}) => {
  const styles = {
    cancel:
      "w-full bg-white hover:bg-hover-gray active:bg-active-gray border border-light-gray text-light-gray py-3 rounded-[30px]",
    save: "w-full bg-yellow hover:bg-dark-yellow active:bg-darker-yellow border border-yellow text-black py-3 rounded-[30px] text-nowrap",
  };

  return (
    <button
      className={`${styles[variant] || "default-styles"} ${className}`}
      onClick={onClick}
      {...props}
    >
      <StyledH6Heading className={fontColor}>{label}</StyledH6Heading>
    </button>
  );
};

export default Button;
