export const content = {

  blogCardsData: [
    {
      id: 1,
      image: "/assets/blog/blog1.png",
      date: "Jan 2, 2024",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.asuhd",
      content: [
        {
          heading: "To Crypto Or Not To Crypto That Is The Question That Leads To Happy",
          paragraphs: [
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
            "Why do we use it? It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
          ],
          images: ["/assets/blog/blog2.png"],
        },
      ],
    },
    {
      id: 2,
      image: "/assets/blog/blog2.png",
      date: "Jan 10, 2024",
      desc: "Ut enim ad minim veniamS,Renewable energy sources are becoming increasingly important in our quest for sustainable living.",
      content: [
        {
          heading: "The Rise of AI: How It's Changing the World",
          paragraphs: [
            "Artificial Intelligence (AI) has been a rapidly growing field in recent years...",
            "What are the implications of AI in our daily lives and future advancements? Let's explore...",
          ],
          images: ["/assets/blog/blog1.png"],
        },
      ],
    },
    {
      id: 3,
      image: "/assets/blog/blog3.png",
      date: "Feb 5, 2024",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      content: [
        {
          heading: "Exploring the Wonders of Quantum Computing",
          paragraphs: [
            "Quantum computing is set to revolutionize the way we approach problem-solving...",
            "Understanding the basics of quantum mechanics can help us appreciate the potential of this technology...",
          ],
          images: ["/assets/blog/blog4.png"],
        },
      ],
    },
    {
      id: 4,
      image: "/assets/blog/blog4.png",
      date: "Feb 20, 2024",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      content: [
        {
          heading: "The Future of Renewable Energy",
          paragraphs: [
            "Renewable energy sources are becoming increasingly important in our quest for sustainable living...",
            "How can we harness the power of the sun, wind, and water to create a greener future?",
          ],
          images: ["/assets/blog/blog3.png"],
        },
      ],
    },
    {
      id: 5,
      image: "/assets/blog/blog5.png",
      date: "Mar 1, 2024",
      desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
      content: [
        {
          heading: "The Impact of Social Media on Society",
          paragraphs: [
            "Social media has transformed the way we communicate and interact with each other...",
            "What are the positive and negative effects of social media on our mental health and societal dynamics?",
          ],
          images: ["/assets/blog/socialmedia.svg"],
        },
      ],
    },
    {
      id: 6,
      image: "/assets/blog/blog6.png",
      date: "Mar 15, 2024",
      desc: "Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
      content: [
        {
          heading: "Advancements in Space Exploration",
          paragraphs: [
            "Space exploration has always fascinated humanity, and recent advancements are pushing the boundaries further...",
            "From Mars missions to new telescopes, let's delve into the latest developments in space technology...",
          ],
          images: ["/assets/blog/space.svg"],
        },
      ],
    },
    {
      id: 7,
      image: "/assets/blog/blog1.png",
      date: "Apr 1, 2024",
      desc: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
      content: [
        {
          heading: "The Evolution of E-commerce",
          paragraphs: [
            "E-commerce has transformed the retail industry, providing new opportunities for businesses and consumers alike...",
            "What are the latest trends in e-commerce, and how are they shaping the future of shopping?",
          ],
          images: ["/assets/blog/ecommerce.svg"],
        },
      ],
    },
    {
      id: 8,
      image: "//assets/blog/blog6.png",
      date: "Apr 18, 2024",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
      content: [
        {
          heading: "The Role of Biotechnology in Modern Medicine",
          paragraphs: [
            "Biotechnology is playing a crucial role in the advancement of modern medicine...",
            "How are biotech innovations improving our ability to treat and prevent diseases?",
          ],
          images: ["/assets/blog/biotech.svg"],
        },
      ],
    },
    {
      id: 9,
      image: "/assets/blog/blog6.png",
      date: "May 5, 2024",
      desc: "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
      content: [
        {
          heading: "Cybersecurity in the Digital Age",
          paragraphs: [
            "With the increasing reliance on digital platforms, cybersecurity has become more important than ever...",
            "What measures can individuals and organizations take to protect themselves from cyber threats?",
          ],
          images: ["/assets/blog/cybersecurity.svg"],
        },
      ],
    },
    {
      id: 10,
      image: "/assets/blog/blog3.png",
      date: "May 20, 2024",
      desc: "Ut enim ad minima veniam.",
      content: [
        {
          heading: "The Importance of Mental Health Awareness",
          paragraphs: [
            "Mental health is a critical aspect of overall well-being, yet it often goes overlooked...",
            "Raising awareness and understanding mental health can lead to better support and resources for those in need...",
          ],
          images: ["/assets/blog/mentalhealth.svg"],
        },
      ],
    },
    {
      id: 11,
      image: "/assets/blog/blog5.png",
      date: "Jun 1, 2024",
      desc: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself.",
      content: [
        {
          heading: "Innovations in the Automotive Industry",
          paragraphs: [
            "The automotive industry is undergoing a significant transformation with the rise of electric and autonomous vehicles...",
            "What are the latest innovations, and how are they shaping the future of transportation?",
          ],
          images: ["/assets/blog/automotive.svg"],
        },
      ],
    },
    {
      id: 12,
      image: "/assets/blog/blog4.png",
      date: "Jun 15, 2024",
      desc: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.",
      content: [
        {
          heading: "The Growth of the Gig Economy",
          paragraphs: [
            "The gig economy is reshaping the traditional job market, offering new opportunities and challenges...",
            "How can workers and businesses adapt to this changing landscape?",
          ],
          images: ["/assets/blog/gigeconomy.svg"],
        },
      ],
    }
  ],
  iconClasses: {
    email: "/assets/icons/email-icon.svg",
    walletAddress: "/assets/icons/wallet-icon.svg",
    totalClaimed: "/assets/icons/coins-icon.svg",
    amount: "/assets/icons/coins-icon.svg",
    date: "/assets/icons/date-icon.svg",
  },
  tablesHeadData: {
    topClaimers: [
      { label: "Name", key: "displayName", alignment: "text-left" },
      { label: "Wallet Address", key: "walletAddress", alignment: "text-left" },
      {
        label: "Total Claimed Tokens",
        key: "totalClaimed",
        alignment: "text-left",
      },
    ],
    userDetails: [
      { label: "Name", key: "displayName", alignment: "text-left" },
      { label: "Email Address", key: "email", alignment: "text-left" },
      { label: "Wallet Address", key: "walletAddress", alignment: "text-left" },
      {
        label: "Total Claimed Tokens",
        key: "totalClaimed",
        alignment: "text-left",
      },
      { label: "", key: "avatar640", alignment: "text-left" },
    ],
    singleUser: [
      { label: "Date", key: "claimDate", alignment: "text-left" },
      { label: "Wallet Address", key: "wallet", alignment: "text-left" },
      {
        label: "Total Claimed Tokens",
        key: "amount",
        alignment: "text-left",
      },
    ],
    blogsCategories: [
      { label: "Name", key: "name", alignment: "text-left" },
      { label: "Blog Count", key: "blogCount", alignment: "text-center" },
      { label: "Slug", key: "slug", alignment: "text-center" },
      { label: "Created On", key: "createdAt", alignment: "text-left" },
      { label: "", key: "isActive", alignment: "text-center" },
    ],
  },
};
