import React from 'react';

const BlogDetailSkeleton = () => {
    return (
        <div className="flex flex-col gap-5 pt-5 w-full">
            {/* Heading */}
            <div className="pl-10">
                <div className="w-24 h-6 bg-gray-200 animate-pulse rounded"></div>
            </div>
            {/* Blog Detail Start */}
            <div className="w-full px-5 sm:px-10 lg:px-20 xl:px-40 pb-20">
                {/* Blog Image */}
                <div className="w-full flex flex-col justify-center items-center">
                    <div className="w-full h-64 bg-gray-200 animate-pulse rounded-[20px]"></div>
                </div>
                <div className="w-full flex flex-col justify-center items-start gap-2">
                    <div className="w-full flex justify-between items-center pt-6">
                        <div className="w-24 h-4 bg-gray-200 animate-pulse rounded"></div>
                        <div className="w-24 h-4 bg-gray-200 animate-pulse rounded"></div>
                    </div>
                    <div className="w-full flex flex-col justify-center items-start gap-3">
                        <div className="w-full h-8 bg-gray-200 animate-pulse rounded"></div>
                        <div className="w-full h-4 bg-gray-200 animate-pulse rounded"></div>
                        <div className="w-full h-4 bg-gray-200 animate-pulse rounded"></div>
                        <div className="w-full h-4 bg-gray-200 animate-pulse rounded"></div>
                        <div className="w-full h-4 bg-gray-200 animate-pulse rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetailSkeleton;