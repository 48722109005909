import React, { createContext, useState, useContext } from "react";

const StateContext = createContext();

export const UserContext = () => useContext(StateContext);

export const UserContextProvider = ({ children }) => {
    const profileData = JSON.parse(localStorage.getItem("user"));

    const [data, setData] = useState([]);
    const [claimerStats, setClaimerStats] = useState(null);
    const [topHoldersData, setTopHoldersData] = useState([]);
    const [singleUserdata, setSingleUserData] = useState([]);
    const [profile, setProfile] = useState(profileData ?? null);
    const [totalHoldersData, setTotalHoldersData] = useState("");
    const [singleUserTableData, setSingleUserTableData] = useState([]);

    return (
        <StateContext.Provider
            value={{
                data,
                profile,
                claimerStats,
                topHoldersData,
                singleUserdata,
                totalHoldersData,
                singleUserTableData,
                setData,
                setProfile,
                setClaimerStats,
                setSingleUserData,
                setTopHoldersData,
                setTotalHoldersData,
                setSingleUserTableData,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};
