import React, { useEffect, useState } from "react";
import BlogCard from "../../components/blogCard/BlogCard";
import CommonModal from "../../components/commonModal/CommonModal";
import CommonCard from "../../components/commonCard/CommonCard";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";
import { useStateContext } from "../../context/StateContext";
import { callApi } from "../../API/api";
import { toast } from "react-toastify";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import StyledLgText from "../../common/components/styledLgText/styledLgText";

const BlogSection = () => {
  const [blogCards, setBlogCards] = useState([]);
  // const [deleteIndex, setDeleteIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Start with loading state true
  const itemsPerPage = 3;
  const navigate = useNavigate();
  const { blogItem, setIsSubmitting } = useStateContext();

  useEffect(() => {
    fetchAllBlogs();
  }, [currentPage]);

  const fetchAllBlogs = async () => {
    try {
      setIsLoading(true);
      const response = await callApi("/blogs/all-blogs-admin", "GET", null, {
        page: currentPage + 1,
        perPage: itemsPerPage,
      });
      if (response.success) {
        setBlogCards(response.data);
        setTotalPages(response.meta.totalPages);
      } else {
        toast.error("Failed to fetch blogs: " + response.message);
      }
    } catch (error) {
      toast.error("Error fetching blogs: " + error.message);
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  const handleDeleteCard = async () => {
    try {
      setIsSubmitting(true)
      const response = await callApi(`/blogs/delete-by-id`, "POST", {
        id: isModalOpen?._id,
      });
      if (response.success) {
        // const updatedBlogCards = blogCards.filter((_, i) => i !== index);
        // setBlogCards(updatedBlogCards);
        // setTotalPages(response.meta.totalPages);
        toast.success(response.message ?? "Blog deleted successfully.");
        fetchAllBlogs();
      } else {
        toast.error("Failed to delete blog: " + response.message);
      }
    } catch (error) {
      toast.error("Error deleting blog: " + error.message);
    } finally {
      setIsModalOpen(false);
      setIsSubmitting(false)
    }
  };

  const handleCloseModal = () => {
    // setDeleteIndex(null);
    setIsModalOpen(null);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleViewBlog = (id) => {
    navigate(`/single-blog/${id}`);
  };

  return (
    <>
      <CommonCard>
        <div className="flex flex-col items-end justify-center w-full gap-5 px-5 py-5 lg:px-10">
          {/* ADD NEW BLOG */}
          <Link
            to="/add-new-blog"
            className="flex items-center justify-end w-full gap-2"
          >
            <img src="/assets/blog/add_circle.svg" alt="Add Circle" />
            <StyledH6Heading fontColor="text-yellow" children="Add New Blog" />
          </Link>
          {/* Blogs Cards or Skeleton Loading */}
          {isLoading ? (
            <SkeletonLoading type="card" />
          ) : (
            <>
              {blogCards.length === 0 ? (
                <div className="flex items-center justify-center w-full h-40">
                  <StyledLgText className="text-black">
                    No Blogs Found
                  </StyledLgText>
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
                  {blogCards.map((card) => (
                    <div key={card._id} className="relative w-full">
                      <BlogCard
                        item={card}
                        index={card._id}
                        // onDelete={() => handleOpenModal(index)}
                        setIsModalOpen={setIsModalOpen}
                        onView={() => handleViewBlog(card._id)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {/* Pagination */}
          <div className="flex items-center justify-end w-full">
            <Pagination pageCount={totalPages} onPageChange={handlePageClick} />
          </div>
        </div>
      </CommonCard>
      {!!isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CommonModal
            title="Confirm"
            description="Are you sure you want to delete this blog post?"
            label="Cancel"
            fontColor="text-light-gray"
            label2="Delete"
            fontColor2="text-black"
            onClose={handleCloseModal}
            onConfirm={handleDeleteCard}
          />
        </div>
      )}
    </>
  );
};

export default BlogSection;
