import React, { useEffect, useState } from "react";

// Files Import
import { content } from "../../data/data";
import Table from "../../components/Table/Table";
import TopHolders from "../../common/components/TopHolders/TopHolders";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import StyledH2Heading from "../../common/components/styledh2heading/StyledH2Heading";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";
import { callApi } from "../../API/api";
import TextSkeleton from "../../components/textSkeleton/TextSkeleton";
import { useStateContext } from "../../context/StateContext";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import { UserContext } from "../../context/userContext/UserContext";

const DashboardSection = () => {
  const { isLoading, setIsLoading } = useStateContext();
  const { claimerStats, setClaimerStats, topHoldersData, setTopHoldersData, totalHoldersData, setTotalHoldersData } = UserContext();

  const cardsData = [
    {
      title: "Total Users Registered",
      value: claimerStats?.totalUsers,
      icon: "/assets/icons/user-icon.svg",
      bgClass: "bg-white",
      borderClass: "border-white",
    },
    {
      title: "Total Claimed",
      value: claimerStats?.totalClaimedTokens,
      icon: "/assets/icons/nft.svg",
      bgClass: "bg-[#FDF8E5]",
      borderClass: "border-yellow",
    },
    {
      title: "No. of Holders",
      value: totalHoldersData,
      icon: "/assets/icons/shareholder.svg",
      bgClass: "bg-[#FDF4E8]",
      borderClass: "border-[#F3B264]",
    },
  ];

  const getClaimersStats = async () => {
    try {
      if (!claimerStats) {
        setIsLoading(true);
        const response = await callApi("/home/get-claimers-stats", "GET");
        setClaimerStats(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
    }
  };

  const getTopHolders = async () => {
    try {
      setIsLoading(true);
      const response = await callApi("/home/get-top-holders", "GET");
      setTopHoldersData(response?.data);
      setIsLoading(false);
    } catch (error) {
    }
  };

  const getAllHolders = async () => {
    try {
      const response = await callApi("/home/get-total-holders", "GET");
      setTotalHoldersData(response?.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    getClaimersStats();
    getAllHolders();
    getTopHolders();
  }, []);

  return (
    <div className="flex flex-col items-center w-full gap-5">
      {/* Dashboard Title */}
      <div className="flex items-center justify-start w-full pl-5">
        <StyledH3Heading children="Dashboard" fontColor="text-black" />
      </div>
      {/* Dashboard Cards */}
      <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`w-full shadow-md rounded-2xl px-7 lg:px-5 xl:px-7 pt-5 pb-8 flex flex-col items-center gap-5 ${index === 0 ? "bg-yellow" : "bg-white"
              }`}
          >
            <div className="flex w-full text-start">
              <StyledH6Heading children={card.title} fontColor="text-black" />
            </div>
            <div className="flex items-center justify-between w-full">
              <StyledH2Heading
                fontColor="text-black"
              >
                {isLoading ? <TextSkeleton width="w-10" /> : card.value ? card.value : "0"}
              </StyledH2Heading>
              <div
                className={`flex items-center justify-center ${card.bgClass} ${card.borderClass} h-20 w-20 rounded-2xl border`}
              >
                <img src={card.icon} alt="icon" className="min-w-8" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Table */}
      <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
        {/* Left Side */}
        <div className="col-span-1 md:col-span-2">
          <StyledH6Heading fontColor="text-black font-bold pl-5 py-5">
            Top Claimers
          </StyledH6Heading>
          {isLoading ? <SkeletonLoading type="topClaimers" />
            :
            <Table
              custom={false}
              currentItems={claimerStats?.users?.slice(0, 5)
              }
              head={content.tablesHeadData.topClaimers}
              filteredData={claimerStats?.users}
              isLoading={isLoading}
              type={"topClaimers"}
            />}
        </div>
        {/* Right Side */}
        <div className="col-span-1 md:col-span-2 xl:col-span-1">
          <TopHolders topHolders={topHoldersData} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default DashboardSection;
