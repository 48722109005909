import React from "react";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";
import { Outlet } from "react-router-dom";

const BlogsLayout = () => {
  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full pl-5">
        <StyledH3Heading children={"Blogs"} fontColor={"text-black"} />
      </div>

      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default BlogsLayout;
