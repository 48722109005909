import React from "react";
import StyledLgText from "../../common/components/styledLgText/styledLgText";
import StyledH4Heading from "../../common/components/styledh4heading/StyledH4Heading";
const TokenCard = ({ title, data }) => {
  const cardStyle =
    " w-full flex flex-col justify-center items-center gap-4 border-2 border-light-yellow rounded-2xl bg-white shadow-md hover:shadow-lg transition-all duration-100 py-14 px-6";
  return (
    <div className={cardStyle}>
      <StyledH4Heading children={data ? data : typeof(data) === "string" ? "--" : 0} fontColor={"text-black	break-all"} />
      <StyledLgText children={title} fontColor={"text-black"} />
    </div>
  );
};
export default TokenCard;
