import React from "react";

const StyledCtaText = ({ children, fontColor }) => {
  return (
    <p className={`text-cta font-inter font-semi-bold ${fontColor}`}>
      {children}
    </p>
  );
};

export default StyledCtaText;
