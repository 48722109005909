import React from "react";

const StyledMdBoldText = ({ children, fontColor }) => {
  return (
    <p className={`text-md font-inter font-semibold ${fontColor}`}>
      {children}
    </p>
  );
};

export default StyledMdBoldText;
