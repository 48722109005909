import React from 'react'
import NewPassSection from '../../sections/newPassSection/NewPassSection'

const NewPassPage = () => {
  return (
    <>
      <NewPassSection/>
    </>
  )
}

export default NewPassPage