import React from "react";

const styles = {
  backgroundSize: "cover",
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
  backgroundImage: "url('/assets/background/bg-img.png')",
};

const AuthenticationLayout = ({ children }) => {
  return (
    <div style={styles} className="w-full h-screen">
      <div className="w-full lg:w-[95%] h-screen flex justify-center lg:justify-end items-center">
        <div className="w-[90%] md:w-[638px] min-h-[612px] my-auto bg-white rounded-[22px] shadow-md flex flex-col items-center gap-10 py-5">
          {/* LOGO */}
          <div>
            <img src="/assets/logo/logo.svg" alt="Logo" />
          </div>
          {/* Heading */}
          <div className="w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
