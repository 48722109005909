import React from "react";
import BlogsCategoriesSection from "../../sections/BlogsCategoriesSection/BlogsCategoriesSection";

const BlogsCategoriesPage = () => {
  return (
    <div>
      <BlogsCategoriesSection />
    </div>
  );
};

export default BlogsCategoriesPage;
