import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import CommonModal from "../commonModal/CommonModal";
import { useStateContext } from "../../context/StateContext";
// import BlogCategoryModal from "../blogCategoryModal/BlogCategoryModal";
import StyledMdText from "../../common/components/styledMdText/styledMdText";

const EditCard = ({ custom, category, blogId, type, onDelete }) => {
  // const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { editCategoryItem, setEditCategoryItem, setDeleteModalOpen, setIsCategoryModalOpen } = useStateContext();

  const handleDeleteClick = () => {
    if (type === "blog") {
      onDelete();
    } else {
      setDeleteModalOpen(editCategoryItem);
      setEditCategoryItem(null)
    }
  };

  const handleEditClick = () => {
    setIsCategoryModalOpen(true);
    setEditCategoryItem(editCategoryItem)
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2 p-2 bg-white rounded-lg shadow-lg ">
      {!custom && (
        <div className="w-full rounded-lg hover:bg-[#F9F9F9] py-2 px-6">
          <Link
            className="flex items-center justify-start gap-6"
            to={`/single-blog/${blogId}`}
          >
            <img className="w-4 h-4" src="/assets/blog/eye.svg" alt="" />
            <StyledMdText
              children={"View"}
              fontColor={"text-light-gray font-semibold"}
            />
          </Link>
        </div>
      )}
      {!custom ? (
        <div className="w-full rounded-lg hover:bg-[#F9F9F9] py-2 px-6">
          <Link
            className="flex items-center justify-start gap-6"
            to={`/edit-blog/${blogId}`}
          >
            <img className="w-4 h-4" src="/assets/blog/edit.svg" alt="" />
            <StyledMdText
              children={"Edit"}
              fontColor={"text-light-gray font-semibold"}
            />
          </Link>
        </div>
      ) : (
        <div className="w-full rounded-lg hover:bg-[#F9F9F9] py-2 px-6">
          <Link
            className="flex items-center justify-start gap-6"
            onClick={handleEditClick}
          >
            <img className="w-4 h-4" src="/assets/blog/edit.svg" alt="" />
            <StyledMdText
              children={"Edit"}
              fontColor={"text-light-gray font-semibold"}
            />
          </Link>
        </div>
      )}

      <div
        onClick={handleDeleteClick}
        className="w-full cursor-pointer text-[#E21D12] rounded-lg hover:bg-[#F9F9F9] flex justify-start items-center gap-6 py-2 px-6"
      >
        <img className="w-4 h-4 " src="/assets/blog/trash.svg" alt="" />
        <StyledMdText children={"Delete"} fontColor={"font-semibold"} />
      </div>


    </div>
  );
};

export default EditCard;
