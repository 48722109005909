import React, { useEffect, useState } from "react";
// Files Import
import { callApi } from "../../API/api";
import TokenCard from "../../components/tokenCard/TokenCard";
import CommonCard from "../../components/commonCard/CommonCard";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import { useStateContext } from "../../context/StateContext";

const TokenSection = () => {
  const [tokensData, setTokensData] = useState([]);
  const { isLoading, setIsLoading } = useStateContext();

  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading(true);
      try {
        const response = await callApi("/kpi/get-kpi-token", "GET");
        if (response.success) {
          setTokensData(
            Array.isArray(response.data) ? response.data : [response.data]
          );
        }
      } catch (error) {
        console.error("Error fetching tokens", error.message);
      }
      setIsLoading(false);
    };
    fetchTokens();
  }, [setIsLoading]);

  const tokenDetails = [
    { key: "name", title: "Token Name" },
    { key: "symbol", title: "Token Symbol" },
    { key: "decimals", title: "Token Decimals" },
    { key: "supply", title: "Total Supply" },
    { key: "marketCap", title: "Market Cap" },
    { key: "currentPrice", title: "Current Price" },
  ];

  // const demoData = [
  //   {
  //     name: "",
  //     symbol: "",
  //     decimals: 877943,
  //     supply: null,
  //     holders: undefined,
  //     marketCap: "",
  //     currentPrice: "",
  //   },
  // ];

  return (
    <div className="w-full flex flex-col gap-5">
      <StyledH3Heading
        children={"KPI of Tokens"}
        fontColor={"text-black pl-5"}
      />
      <CommonCard>
        <div className="w-full px-5 md:px-10 xl:px-20 pt-16 pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {isLoading
            ? Array.from({ length: tokenDetails.length }).map((_, index) => (
              <SkeletonLoading key={index} type="token" />
            ))
            : tokensData.map((token, index) =>
              tokenDetails.map((detail) => (
                <TokenCard
                  key={`${detail.key}-${index}`}
                  title={detail.title}
                  data={token[detail.key]}
                />
              ))
            )}
        </div>
      </CommonCard>
    </div>
  );
};

export default TokenSection;
