import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import { content } from "../../data/data";
import { useParams } from "react-router-dom";
import Table from "../../components/Table/Table";
import { slicedAddress } from "../../helpers/helpers";
import { useStateContext } from "../../context/StateContext";
import SearchField from "../../components/SearchField/SearchField";
import { UserContext } from "../../context/userContext/UserContext";
import TextSkeleton from "../../components/textSkeleton/TextSkeleton";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledLgText from "../../common/components/styledLgText/styledLgText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";

const SingleUser = () => {
  const { id } = useParams();
  const [meta, setMeta] = useState({
    total: 0,
    totalPages: 0,
    page: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const { isLoading, setIsLoading } = useStateContext();
  const { singleUserdata, setSingleUserData, singleUserTableData, setSingleUserTableData } = UserContext();
  const itemsPerPage = 5;

  const getSingleUserData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        userId: id
      };
      const response = await callApi('/users/get-user-data', 'POST', payload);
      setSingleUserData(response.data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const getSingleUserTableData = async () => {
    try {
      setIsLoading(true)
      const response = await callApi('/users/get-user-claims', 'POST', {
        userId: id,
      }, {
        page: currentPage + 1,
        perPage: itemsPerPage
      });
      setSingleUserTableData(response.data);
      setMeta(response.meta);
      setIsLoading(false)
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getSingleUserData();
    getSingleUserTableData();
  }, [id, currentPage]); // Add currentPage dependency

  const filteredData = useMemo(
    () =>
      singleUserTableData.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      ),
    [searchQuery, singleUserTableData]
  );

  const handlePageClick = useCallback(({ selected }) => {
    setCurrentPage(selected);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  }, []);

  const claimed = useMemo(
    () => [
      { label: "Claimed", value: `${singleUserdata?.totalClaimed || 0} FITS` },
      { label: "Holding", value: `${singleUserdata?.claimable || 0} FITS` },
      { label: "Claimable", value: `${singleUserdata?.holding || 0} FITS` },
    ],
    [singleUserdata.totalClaimed, singleUserdata.claimable, singleUserdata.holding]
  );

  const fullName = `${singleUserdata.firstName ?? "-"} ${singleUserdata.lastName ?? "-"}`;

  return (
    <div className="flex flex-col w-full gap-5">
      {singleUserdata && (
        <>
          {/* Heading */}
          <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center">
            <div>
              <StyledH3Heading
                children={"User details"}
                fontColor={"text-black pl-5"}
              />
            </div>
            <div className="flex flex-col items-end w-full gap-2 md:flex-row md:items-center md:gap-5 md:w-fit">
              <StyledMdText
                fontColor={"text-light-gray text-nowrap"}
                children={"Search by date"}
              />
              <div className="w-full lg:w-[349px]">
                <SearchField
                  searchQuery={searchQuery}
                  handleSearchChange={handleSearchChange}
                />
              </div>
            </div>
          </div>

          {/* User Detail */}
          <div className="grid w-full grid-cols-5 gap-4 mb-1">
            {/* User Profile */}
            <div className="col-span-5 md:col-span-3 flex flex-col sm:flex-row items-center text-center sm:text-start gap-5 xl:gap-10 py-5 px-7 bg-gradient-to-t from-[#FDB302] to-[#FBCF35] rounded-[22px] self-stretch">
              <div className="lg:w-36 xl:w-44 h-[175px] w-[175px]">
                {isLoading ? <SkeletonLoading type="profileImage" /> : <img src={singleUserdata.avatar640 ? singleUserdata.avatar640 : "assets/icons/profiles/alt-avatar.png"} alt="User" height="175px" width="175px" className="w-full rounded-full" />}
              </div>
              <div>
                <StyledH3Heading
                  children={
                    isLoading ? <TextSkeleton width="w-40" /> : fullName}
                  fontColor="text-black font-bold mb-1"
                />
                <div className="flex items-center gap-2">
                  <img
                    src="/assets/Email.svg"
                    alt="email-icon"
                    className="w-6 h-6"
                  />
                  <StyledLgText
                    children={singleUserdata.email ? singleUserdata.email : "--" || <TextSkeleton width="w-40" />}
                    fontColor="text-black"
                  />
                </div>
              </div>
            </div>
            {/* User History */}
            <div className="col-span-5 md:col-span-2 flex flex-col justify-center items-center gap-3 p-10 bg-gradient-to-t from-[#FDB302] to-[#FBCF35] rounded-[22px] self-stretch">
              {claimed.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center w-full gap-3"
                >
                  <div className="flex items-center justify-between w-full">
                    <StyledLgText
                      children={item.label}
                      fontColor="text-black"
                    />
                    <StyledH6Heading
                      children={isLoading ? <TextSkeleton width="w-10" /> : item.value}
                      fontColor="text-black font-bold"
                    />
                  </div>
                  {index < claimed.length - 1 && (
                    <div className="w-full border-b border-dark-gray opacity-[30%]"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* User Table */}
      {isLoading ?
        <SkeletonLoading type="singleUser" /> :
        <Table
          custom={true}
          currentItems={filteredData}
          head={content.tablesHeadData.singleUser}
          pageCount={meta.totalPages}
          handlePageClick={handlePageClick}
        />}
    </div>
  );
};

export default SingleUser;
