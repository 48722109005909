import React from "react";
import DashboardSection from "../../sections/dashBoardSection/DashboardSection";

const DashboardPage = () => {
  return (
    <>
      <DashboardSection />
    </>
  );
};

export default DashboardPage;
