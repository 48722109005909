import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Files Import
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledCtaText from "../../common/components/styledCtaText/styledCtaText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import AuthenticationLayout from "../../components/AuthenticationLayout/AuthenticationLayout";
import { useStateContext } from "../../context/StateContext";

const OTPSection = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [timer, setTimer] = useState(
    parseInt(localStorage.getItem("timer")) || 60
  );
  const [resendEnabled, setResendEnabled] = useState(false);
  const [email, setEmail] = useState("");
  const { isSubmitting, setIsSubmitting } = useStateContext();

  useEffect(() => {
    const getEmail = sessionStorage.getItem("email");
    if (getEmail) {
      setEmail(getEmail);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("timer", timer);
  }, [timer]);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    if (timer === 0) {
      setResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setSubmitEnabled(otp.every((val) => val.trim() !== ""));
  }, [otp]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").split("");
    if (pasteData.length === otp.length) {
      setOtp(pasteData);
    }
  };


  const handleResend = async (event) => {
    event.preventDefault();
    try {
      const response = await callApi("auth/change-email", "POST", { email });
      if (response.status === 200) {
        if (response.success) {
          setTimer(60);
          sessionStorage.setItem("email", email);
          localStorage.setItem("timer", 5);
          toast.success(response.message);
          setTimeout(() => {
            setIsSubmitting(true);
          }, 60000)
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "") {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleSubmit = async () => {
    const enteredOTP = otp.join("");
    try {
      setIsSubmitting(true);
      const response = await callApi("auth/verify-email-otp", "POST", {
        email,
        otp: enteredOTP,
      });
      if (response.success) {
        const { token } = response.data;
        sessionStorage.removeItem("email");
        // toast.success(response.message || "Incorrect OTP");
        navigate(`/reset-password?token=${token}`);
      } else {
        toast.error("Incorrect OTP");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while verifying OTP.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthenticationLayout>
      <div className="w-full flex flex-col gap-12 px-5 sm:px-28">
        {/* Heading */}
        <div className="w-full flex flex-col justify-center">
          <StyledH3Heading children={"My Code Is"} fontColor={"text-black"} />
          <div className="flex justify-between items-center w-full">
            {/* Recent OTP Email */}
            <StyledMdText
              children={email}
              fontColor={"text-light-gray"}
              className=""
            />
            {/* Resend Button */}
            <div className="text-right text-md font-semibold ">
              <span className="text-yellow">
                {`${String(Math.floor(timer / 60)).padStart(2, "0")}:${String(
                  timer % 60
                ).padStart(2, "0")}`}
              </span>{" "}
              {resendEnabled ? (
                <button
                  className="custom-textLine text-light-gray"
                  onClick={handleResend}
                >
                  Resend
                </button>
              ) : (
                <span className="text-light-gray cursor-default">Resend</span>
              )}
            </div>
          </div>
        </div>

        {/* OTP Inputs */}
        <div className="w-full flex justify-center gap-2">
          {otp.map((data, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength="1"
              className="border border-light-gray rounded-lg w-[20%] h-14 text-center text-lg font-semibold outline-none"
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
              onPaste={handlePaste}
            />
          ))}
        </div>

        {/* Button - Click here email Change */}
        <div className="flex flex-col gap-6 items-center mt-4">
          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            type="submit"
            className={`rounded-full w-full py-4 ${submitEnabled
              ? "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
              : "bg-darker-yellow opacity-80 cursor-not-allowed"
              }`}
            disabled={!submitEnabled}
          >
            <StyledCtaText
              children={isSubmitting ? "Submitting" : "Submit"}
              fontColor="text-black font-semibold"
            />
          </button>

          {/* Change Email >>> */}
          <StyledMdText fontColor={"text-light-gray"}>
            Want to change email?{" "}
            <Link
              to="/forgot-password"
              className="custom-textLine text-yellow font-semibold"
            >
              Click Here
            </Link>
          </StyledMdText>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default OTPSection;
