import React from "react";
import  OTPSection  from "../../sections/otpSection/OTPSection";

const OTPPage = () => {
  return <>
    <OTPSection/>
  </>;
};

export default OTPPage;
