import React, { useRef, useState, useCallback, useEffect } from "react";

// FIles Import
import { Link } from "react-router-dom";
import EditCard from "../editCard/EditCard";
import Pagination from "../../components/Pagination/Pagination";
import { content } from "../../data/data";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import SkeletonLoading from "../skeleton/SkeletonLoading";
import { useStateContext } from "../../context/StateContext";

const Table = ({
  currentItems,
  head,
  type,
  pageCount,
  handlePageClick,
  custom,
  currentPage = 0,
  isBlogCategory,
}) => {
  const { setEditCategoryItem } = useStateContext();
  const editCardRef = useRef(null);
  const [isEditCardOpen, setIsEditCardOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const handleOpenModal = useCallback((event, item) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom + 10, left: rect.left - 175 });
    setIsEditCardOpen((prev) => !prev);
    setEditCategoryItem(item);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (editCardRef.current && !editCardRef.current.contains(event.target)) {
      setIsEditCardOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getCellContent = (item, column) => {
    const hasProperty = item.hasOwnProperty(column.key);
    const icon = hasProperty && content.iconClasses[column.key];
    // if (!hasProperty) {
    //   return <div className="">--</div>;
    // }
    if (column.key === "name") {
      return (
        <div className="flex items-center gap-2">
          {!isBlogCategory && (
            <div className="flex-shrink-0 w-10 h-10">
              <img src={item.userImg ? item.userImg : "assets/icons/profiles/alt-avatar.png"} alt="User" className="w-10" />
            </div>
          )}
          <div>
            <StyledMdText
              fontColor="text-gray-900 whitespace-no-wrap"
            >
              {item.name ? item.name : "--"}
            </StyledMdText>
          </div>
        </div>
      );
    }
    if (column.key === "avatar640") {
      return (
        <Link to={`/user-details/${item._id}`}>
          <div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
            >
              <path
                d="M1 1L2.76297 2.74731C4.1689 4.14075 4.87187 4.83747 4.98011 5.68667C5.00663 5.89473 5.00663 6.10527 4.98011 6.31333C4.87187 7.16252 4.1689 7.85925 2.76297 9.25269L1 11"
                stroke="#0C0C0B"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </Link>
      );
    }
    if (column.key === "displayName") {
      return (
        <div className="flex items-center gap-2">
          {!isBlogCategory && (
            <div className="flex-shrink-0 w-10 h-10">
              <img
                src={item.avatar640 ? item.avatar640 : "assets/icons/profiles/alt-avatar.png"}
                alt="User"
                className="w-10 rounded-full"
              />
            </div>
          )}
          <div>
            <StyledMdText
              fontColor="text-gray-900 whitespace-no-wrap"
            >
              {item.displayName ? item.displayName : "--"}
            </StyledMdText>
          </div>
        </div>
      );
    }
    if (column.key === "isActive") {
      return (
        <div className="flex justify-end">
          <img
            src="/assets/blog/dots.svg"
            alt="dots"
            className="w-5 rotate-90 cursor-pointer"
            onClick={(e) => handleOpenModal(e, item)}
          />
        </div>
      );
    }
    if (column.key === "walletAddress" || column.key === "wallet") {
      return (
        <div className="flex items-center gap-2">
          <img
            src="/assets/icons/wallet-icon.svg"
            width="16"
            height="16"
            alt="Wallet Address"
          />
          <StyledMdText
            fontColor="text-gray-900"
          >
            {
              item[column.key]
                ? `${item[column.key].slice(0, 4)}......${item[
                  column.key
                ].slice(-4)}`
                : "--"
            }
          </StyledMdText>
        </div>
      );
    }
    if (column.key === "claimDate" || column.key === "createdAt") {
      return (
        <div className="flex items-center gap-2">
          <img
            src="/assets/icons/date-icon.svg"
            width="16"
            height="16"
            alt="Date"
          />
          <StyledMdText
            fontColor="text-gray-900"
          >
            {item[column.key] ? new Date(item[column.key]).toLocaleDateString() : "--"}
          </StyledMdText>
        </div>
      );
    }
    return (
      <div
        className={`flex items-center ${!isBlogCategory ? "" : "justify-center"
          }`}
      >
        {icon && !isBlogCategory && (
          <img src={icon} alt={`${column.key} icon`} className="w-4 h-4 mr-2" />
        )}
        <StyledMdText fontColor="text-gray-900" children={item[column.key] ? item[column.key] : '--'} />
      </div>
    );
  };

  return (
    <div className="w-full overflow-x-auto bg-white shadow-md sm:rounded-3xl no-scrollbar">
      <table className="w-full table-auto">
        <thead className="text-sm text-black bg-yellow text-nowrap">
          <tr>
            {head?.map((column, index) => (
              <th
                key={index}
                className={`px-10 py-5 whitespace-nowrap ${column.alignment}`}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems?.length > 0 ? (
            currentItems?.map((item, rowIndex) => (
              <tr key={rowIndex} className="text-nowrap">
                {head?.map((column, colIndex) => (
                  <td key={colIndex} className="px-10 py-5 border-b">
                    {getCellContent(item, column)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={head.length} className="py-10 text-center">
                No entries found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={`flex justify-end pr-8 ${custom ? "py-5" : "py-3"}`}>
        {custom ? (
          currentItems &&
          currentItems.length > 0 && (
            <Pagination pageCount={pageCount} onPageChange={handlePageClick} forcePage={currentPage} />
          )
        ) : (
          <Link
            to={"/user-details"}
            className="flex items-center gap-0 font-medium transition-all duration-300 cursor-pointer hover:gap-2 text-md text-yellow"
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 23"
              fill="currentColor"
              className="rotate-180"
            >
              <path
                d="M11.0835 14.4166L7.25016 10.5833C7.16683 10.5 7.10794 10.4097 7.0735 10.3125C7.0385 10.2152 7.021 10.1111 7.021 9.99996C7.021 9.88885 7.0385 9.78468 7.0735 9.68746C7.10794 9.59024 7.16683 9.49996 7.25016 9.41663L11.0835 5.58329C11.2363 5.43051 11.4307 5.35413 11.6668 5.35413C11.9029 5.35413 12.0974 5.43051 12.2502 5.58329C12.4029 5.73607 12.4793 5.93051 12.4793 6.16663C12.4793 6.40274 12.4029 6.59718 12.2502 6.74996L9.00016 9.99996L12.2502 13.25C12.4029 13.4027 12.4793 13.5972 12.4793 13.8333C12.4793 14.0694 12.4029 14.2638 12.2502 14.4166C12.0974 14.5694 11.9029 14.6458 11.6668 14.6458C11.4307 14.6458 11.2363 14.5694 11.0835 14.4166Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        )}
      </div>

      {isEditCardOpen && (
        <div
          ref={editCardRef}
          className="absolute z-10 flex items-center justify-center w-48 h-auto bg-white shadow-lg rounded-xl"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <EditCard custom />
        </div>
      )}
    </div>
  );
};

export default Table;
