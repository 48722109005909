import React from "react";

const CommonCard = ({ children, width }) => {
  return (
    <div
      className={`${width} rounded-[22px] shadow-md border-t-[18px] border-yellow bg-white`}
    >
      {children}
    </div>
  );
};

export default CommonCard;
