import React, { useEffect, useState, useCallback, useMemo } from "react";
import { callApi } from "../../API/api";
import ChangePayoutCard from "../../components/ChangePayoutCard/ChangePayoutCard";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import { useStateContext } from "../../context/StateContext";
import Button from "../../components/buttons/Buttons";
import { toast } from "react-toastify";

const ChangeReward = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [payoutsData, setPayoutsData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const { isLoading, setIsLoading } = useStateContext();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await callApi("/goals/get-reward-goals", "GET");
      if (response.success) {
        const goalsData = response.data;
        delete goalsData._id;
        delete goalsData.createdAt;
        delete goalsData.updatedAt;
        delete goalsData.updatedAt;
        const formattedData = Object.keys(goalsData).map((key) => ({
          id: key,
          ...goalsData[key],
        }));
        setPayoutsData(formattedData);
      }
    } catch (error) {
      toast.error("Error fetching data", error.message);
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleCancel = () => {
    setIsEditable(false);
    setChangedItems([]);
  };

  // const handleSave = async () => {
  //   setIsLoading(true);
  //   try {
  //     const changedDataArray = payoutsData.filter((item) =>
  //       changedItems.includes(item.id)
  //     );
  //     const changedData = changedDataArray.reduce((acc, item) => {
  //       acc[item.id] = { reward: item.reward, goal: item.goal };
  //       return acc;
  //     }, {});

  //     const response = await callApi("/goals/update-reward-goals", "POST", { data: changedData },
  //     );

  //     if (response.success) {
  //       setChangedItems([]);
  //     } else {
  //       console.error("Data Not Updated", response.error);
  //     }
  //   } catch (error) {
  //     console.error("Data Not Updated", error.message);
  //   }
  //   setIsLoading(false);
  // };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const changedDataArray = payoutsData.filter((item) =>
        changedItems.includes(item.id)
      );
      const changedData = changedDataArray.reduce((acc, item) => {
        acc[item.id] = { reward: item.reward, goal: item.goal };
        return acc;
      }, {});

      console.log("Payload to be sent:", changedData);

      const response = await callApi(
        "/goals/update-reward-goals",
        "POST",
        { data: changedData }
      );

      if (response.success) {
        setChangedItems([]);
      } else {
        toast.error("Data Not Updated", response.error);
      }
    } catch (error) {
      toast.error("Data Not Updated", error.message);
    }
    setIsLoading(false);
  };



  const handleRewardChange = useCallback(
    (id, newReward) => {
      setPayoutsData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, reward: newReward } : item
        )
      );
      if (!changedItems.includes(id)) {
        setChangedItems((prevItems) => [...prevItems, id]);
      }
    },
    [changedItems]
  );

  const handleGoalsChange = useCallback(
    (id, newGoal) => {
      setPayoutsData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, goal: newGoal } : item
        )
      );
      if (!changedItems.includes(id)) {
        setChangedItems((prevItems) => [...prevItems, id]);
      }
    },
    [changedItems]
  );

  const memoizedPayoutsData = useMemo(() => payoutsData, [payoutsData]);
  const memoizedIsEditable = useMemo(() => isEditable, [isEditable]);

  return (
    <div className="flex flex-col gap-5 w-full">
      {/* Heading */}
      <div>
        <StyledH3Heading
          fontColor="text-black pl-5"
          children={"Change Daily Payouts"}
        />
      </div>
      <div className="w-full shadow-md bg-white py-5 px-4 md:px-8 rounded-2xl flex flex-col gap-5 pb-10 items-end">
        {!memoizedIsEditable && <EditButton onClick={handleEditClick} />}
        {isLoading ? (
          <SkeletonLoading type="reward" />
        ) : (
          <PayoutCards
            payoutsData={memoizedPayoutsData}
            isEditable={memoizedIsEditable}
            onRewardChange={handleRewardChange}
            onGoalsChange={handleGoalsChange}
          />
        )}
        {memoizedIsEditable && (
          <SaveCancelButtons onCancel={handleCancel} onSave={handleSave} />
        )}
      </div>
    </div>
  );
};

const EditButton = ({ onClick }) => (
  <div onClick={onClick} className="flex items-center gap-1 cursor-pointer">
    <img src="/assets/editpen.svg" alt="Edit" />
    <h6 className="text-yellow">Edit</h6>
  </div>
);

const PayoutCards = React.memo(
  ({ payoutsData, isEditable, onRewardChange, onGoalsChange }) => (
    <div className="flex flex-col gap-7 items-center sm:items-end">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {payoutsData.map((item) => (
          <div key={item.id}>
            <ChangePayoutCard
              key={item.id}
              item={item}
              isEditable={isEditable}
              onRewardChange={onRewardChange}
              onGoalsChange={onGoalsChange}
            />
          </div>
        ))}
      </div>
    </div>
  )
);

const SaveCancelButtons = ({ onCancel, onSave }) => (
  <div className="w-full flex md:justify-end justify-center">
    <div className="w-[100%] md:max-w-[40%] flex md:flex-row  flex-col md:justify-end justify-center items-center gap-2">
      <Button
        className="w-[100%] px-14"
        label="Cancel"
        variant="cancel"
        fontColor="text-light-gray"
        onClick={onCancel}
      />
      <Button
        className="w-[100%] px-14 "
        label="Save"
        variant="save"
        fontColor="text-black"
        onClick={onSave}
      />
    </div>
  </div>
);

export default ChangeReward;
