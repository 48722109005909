import React from "react";

// Files Import
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import Button from "../buttons/Buttons";
import CheckBox from "../checkBox/CheckBox";
import CommonCard from "../commonCard/CommonCard";
import InputField from "../InputField/InputField";
import StyledH5Heading from "../../common/components/styledh5heading/StyledH5Heading";
import { useStateContext } from "../../context/StateContext";

const BlogCategoryModal = ({
  add,
  title,
  label2,
  onClose,
  fontColor,
  fontColor2,
  handleAction,
}) => {
  const { isSubmitting, editCategoryItem, addCategoryItem, setEditCategoryItem, setAddCategoryItem } = useStateContext();

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      onClick={handleBackdropClick}
      className="fixed inset-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-50">
      <CommonCard width="w-11/12 sm:w-[70%] md:w-[60%] xl:w-[45%]">
        <div className="relative flex flex-col gap-4 p-8 bg-white rounded-xl">
          <StyledH5Heading fontColor="text-black">{title}</StyledH5Heading>
          <InputField
            label="Name"
            value={add ? addCategoryItem?.name : editCategoryItem?.name}
            onChange={(e) => {
              add ? setAddCategoryItem({ ...addCategoryItem, name: e.target.value }) :
                setEditCategoryItem({ ...editCategoryItem, name: e.target.value })
            }
            }
          />
          <div className="w-full">
            <CheckBox
              defaultChecked={add ? addCategoryItem?.isActive : editCategoryItem?.isActive}
              value={add ? addCategoryItem?.isActive : editCategoryItem?.isActive}
              onChange={() => {
                add ? setAddCategoryItem({
                  ...addCategoryItem,
                  isActive: !addCategoryItem?.isActive,
                }) : setEditCategoryItem({
                  ...editCategoryItem,
                  isActive: !editCategoryItem?.isActive,
                })
              }
              }
            />
          </div>
          <div className="grid w-full grid-cols-2 gap-2">
            <Button
              label="Cancel"
              variant="cancel"
              fontColor={fontColor}
              onClick={() => {
                onClose();
                { add ? setAddCategoryItem(null) : setEditCategoryItem(null) };
              }}
            />
            <Button
              label={label2}
              variant="save"
              fontColor={fontColor2}
              onClick={handleAction}
              disabled={isSubmitting}
              className={`rounded-full w-full py-4 ${isSubmitting
                ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                }`}
            />
          </div>
        </div>
      </CommonCard>
    </div>
  );
};

BlogCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  fontColor2: PropTypes.string.isRequired,
};

export default BlogCategoryModal;
