import React from 'react'
import NewBlogSection from '../../sections/newBlogSection/NewBlogSection'

const NewBlog = () => {
  return (
    <div>
        <NewBlogSection/>
    </div>
  )
}

export default NewBlog