import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { callApi } from "../../../API/api";
import { removeUserData, setUserData } from "../../../utils/localStorage";
import { UserContext } from "../../../context/userContext/UserContext";

const SimpleLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { claimerStats, setClaimerStats } = UserContext();
  const navigate = useNavigate();
  //
  const chechAuth = async () => {
    try {
      if (!claimerStats) {
        setIsLoading(true);
        const response = await callApi("/home/get-claimers-stats", "GET");
        setClaimerStats(response?.data);
        if (response.status === 200) {
          navigate("/dashboard");
        }
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error === "expired_session") {
          removeUserData();
          setUserData({});
        }
      }
      setIsLoading(false);
      console.error("Failed to fetch data:", error.message);
    }
  };
  // 
  useEffect(() => {
    chechAuth();
  }, []);
  //

  if (isLoading) return <Loader />;
  return (
    <div className="w-[100%]">
      <Outlet />
    </div>
  );
};

export default SimpleLayout;
