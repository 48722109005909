import React from "react";


const SearchField = ({ searchQuery, handleSearchChange }) => {
  return (
    <div className="relative w-full h-fit border border-light-gray rounded-xl px-5">
      <input
        type="text"
        placeholder="Search here..."
        className="w-full outline-none pl-7 h-[40px]"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <img
        src="/assets/icons/search-icon.svg"
        alt="Search"
        className="absolute top-3 left-5"
      />
    </div>
  );
};

export default SearchField;
