import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "../sideBar/SideBar";
import { useStateContext } from "../../../context/StateContext";
import CommonModal from "../../commonModal/CommonModal";
import Loader from "../../../components/loader/Loader";
import { callApi } from "../../../API/api";
import { removeUserData, setUserData } from "../../../utils/localStorage";
import { UserContext } from "../../../context/userContext/UserContext";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { isModalOpen, setIsModalOpen } =
    useStateContext();

  const { claimerStats, setClaimerStats } =
    UserContext();

  useEffect(() => {
    checkAuth();
  }, []);
  //
  const checkAuth = async () => {
    try {
      if (!claimerStats) {
        setIsLoading(true);
        const response = await callApi("/home/get-claimers-stats", "GET");
        setClaimerStats(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error === "expired_session") {
          handleLogout();
        }
      } else if (error.response.status === 400) {
        handleLogout();
      }
      setIsLoading(false);
      console.error("Failed to fetch data:", error.message);
    }
  };

  const handleLogout = async () => {
    try {
      // Simulate logout behavior by removing user data locally
      removeUserData();
      setUserData({});
      setClaimerStats(null);
      // toast.success("Logout successfully");
      navigate(`/login?redirect=${location.pathname}`);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsModalOpen(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 w-full max-w-[1440px] mx-auto">
        <div className="lg:w-[25%] xl:w-[20%]">
          <SideBar />
        </div>
        <div className="w-full lg:w-[75%] xl:w-[80%] pt-[75px] pb-10 px-4 md:pr-10 md:pl-6">
          <Outlet />
        </div>
      </div>
      {/* Logout modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CommonModal
            title="Confirm"
            description="Are you sure you want to logout of this account"
            label="Cancel"
            fontColor="text-light-gray"
            label2="Confirm"
            fontColor2="text-black"
            onClose={() => setIsModalOpen(!isModalOpen)}
            onConfirm={handleLogout}
          />
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
