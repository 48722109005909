import React from 'react'
import BlogSection from '../../sections/blogSection/BlogSection'

const BlogsPage = () => {
  return (
    <div>
      <BlogSection />
    </div>
  )
}

export default BlogsPage