import React, { useState, useEffect } from "react";

// Files Import
import { content } from "../../data/data";
import Table from "../../components/Table/Table";
import SearchField from "../../components/SearchField/SearchField";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import { callApi } from "../../API/api";
import { useStateContext } from "../../context/StateContext";
import { toast } from "react-toastify";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import { UserContext } from "../../context/userContext/UserContext";

const UserDetailsSection = () => {
  const [meta, setMeta] = useState({
    total: 0,
    totalPages: 0,
    page: 0,
  });
  const { data, setData } = UserContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { isLoading, setIsLoading } = useStateContext();
  const itemsPerPage = 5;

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const response = await callApi('/users/get-all-users', 'get', null, {
        page: currentPage + 1,
        perPage: itemsPerPage
      });
      setData(response.data);
      setMeta(response.meta);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getUserData();

    // return () => {
    //   setCurrentPage(0);
    // };
  }, [currentPage]);

  const filteredData = data?.filter((user) =>
    Object.values(user)?.some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex flex-col w-full gap-5">
      {/* Heading */}
      <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center">
        <div>
          <StyledH3Heading
            children={"User details"}
            fontColor={"text-black pl-5"}
          />
        </div>
        <div className="flex flex-col items-end w-full gap-2 md:flex-row md:items-center md:gap-5 md:w-fit">
          <StyledMdText
            fontColor={"text-light-gray text-nowrap"}
            children={`Total Users: ${meta?.total || "please wait.."}`}
          />
          <div className="w-full lg:w-[349px]">
            <SearchField
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      {/* User Table */}
      <div className="w-full">
        {isLoading ? <SkeletonLoading type="user" /> : <Table
          custom={true}
          currentPage={currentPage}
          currentItems={filteredData}
          head={content.tablesHeadData.userDetails}
          pageCount={meta.totalPages}
          handlePageClick={handlePageClick}
        />}
      </div>
    </div>
  );
};

export default UserDetailsSection;
