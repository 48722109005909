export const slicedAddress = (address) => {
    const result = `${address?.slice(0, 4)}....${address?.slice(-4)}`;
    return result;
};

export const cleanSlug = (title = "") => {
    const newSlug = title
        .trim()
        .replace(/[\s_]+/g, "-")
        .replace(/[^A-Za-z0-9-]+/g, "")
        .toLowerCase();
    return newSlug;
};