import React, { useEffect } from "react";

// Files Import
import PropTypes from "prop-types";
import Button from "../buttons/Buttons";
import StyledLgText from "../../common/components/styledLgText/styledLgText";
import StyledH4Heading from "../../common/components/styledh4heading/StyledH4Heading";
import { useStateContext } from "../../context/StateContext";

const CommonModal = ({
  onClose,
  onConfirm,
  title,
  description,
  label,
  label2,
  fontColor,
  disabled = false,
  blogId,
  index
}) => {

  const { isSubmitting } = useStateContext();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="logoutModalTitle"
      aria-describedby="logoutModalDesc"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleBackdropClick}
    >
      <div className="relative w-11/12 max-w-md p-8 mx-auto bg-white sm:w-full rounded-xl">
        <button
          onClick={onClose}
          aria-label="Close modal"
          className="absolute top-4 right-4"
        >
          <img
            src="/assets/cancel.svg"
            alt="Close"
            className="cursor-pointer"
          />
        </button>
        <div className="text-center">
          <StyledH4Heading fontColor="text-black" id="logoutModalTitle">
            {title}
          </StyledH4Heading>
        </div>
        <div className="w-full border-b-2 border-[#F9F9F9] my-4"></div>
        <div
          className="py-2 mb-4 text-center text-light-gray"
          id="logoutModalDesc"
        >
          <StyledLgText>{description}</StyledLgText>
        </div>
        <div className="w-full"></div>
        <div className="flex items-center justify-center w-full gap-2">
          <Button
            label={label}
            variant="cancel"
            fontColor={fontColor}
            onClick={onClose}
            disabled={isSubmitting}
            className={`rounded-full w-full py-4 ${isSubmitting ? "opacity-80 cursor-not-allowed" : ""}`}
          />
          <Button
            label={label2}
            variant="save"
            fontColor={fontColor}
            onClick={onConfirm}
            disabled={isSubmitting}
            className={`rounded-full w-full py-4 ${isSubmitting
              ? "bg-darker-yellow opacity-80 cursor-not-allowed"
              : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
              }`}
          />
        </div>
      </div>
    </div>
  );
};

CommonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  // onDelete: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,

};

export default CommonModal;
