import axios from "axios";

// import { getUserData } from '../utils/localStorage';

const baseURL = process.env.REACT_APP_CONSTANT_API_URL;

export const API = axios.create({
  baseURL,
});

export const callApi = async (
  url,
  method = "POST",
  payload = {},
  params = {}
) => {
  try {
    // const userData = getUserData();
    const token = localStorage.getItem("token");
    const response = await API({
      url,
      method,
      data: payload,
      params,
      headers: {
        "x-auth-token": token,
      },
    });

    return response?.data;
  } catch (error) {
    throw error;
  }
};
