import React from 'react'
import EditBlogSection from '../../sections/editBlogSection/EditBlogSection'

const EditBlog = () => {
  return (
    <div>
        <EditBlogSection/>
    </div>
  )
}

export default EditBlog