import React from 'react'
import ProfileSection from '../../sections/profileSection/ProfileSection'

const ProfilePage = () => {
  return (
    <div>
        <ProfileSection/>
    </div>
  )
}

export default ProfilePage