import React from "react";
import SingleUser from "../../sections/SingleUser/SingleUser";
const SingleUserPage = () => {
  return (
    <div>
      <SingleUser />
    </div>
  );
};

export default SingleUserPage;
