import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { API } from "../../API/api";

export default function RTE({ value = "", classes = "", ...props }) {
  // Text Editor image upload handler
  const image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", blobInfo.blob(), blobInfo.filename());
      API.post("/blogs/upload-blog-content-image", formData, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        onUploadProgress: (progressEvent) => {
          progress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
        .then((response) => {
          if (response.status === 403) {
            reject({ message: "HTTP Error: " + response.status, remove: true });
            return;
          }

          if (response.status < 200 || response.status >= 300) {
            reject("HTTP Error: " + response.status);
            return;
          }

          const json = response.data;

          if (!json || typeof json.path !== "string") {
            reject("Invalid JSON: " + JSON.stringify(json));
            return;
          }

          resolve(json.path);
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with a status other than 200 range
            reject(
              "Image upload failed due to a XHR Transport error. Code: " +
                error.response.status
            );
          } else {
            // Other errors (network, etc.)
            reject("Image upload failed: " + error.message);
          }
        });
    });

  return (
    <Editor
      className={"outline-none " + classes}
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      initialValue=""
      value={value ?? null}
      init={{
        branding: false,
        plugins:
          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        // statusbar: false,
        images_upload_handler: image_upload_handler,
      }}
      {...props}
    />
  );
}
