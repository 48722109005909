import React from "react";
import PropTypes from "prop-types";

const SkeletonLoading = ({ type }) => {
  return (
    <div className="w-full flex flex-wrap">
      {type === "user" && (
        <>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              role="status"
              className="w-[100%] p-4 space-y-4 divide-y divide-gray-200 shadow animate-pulse md:p-6"
            >
              <div className="grid grid-cols-4  gap-x-10 gap-y-10 items-center justify-center">
                <div className="flex items-center gap-4">
                  <div className="h-[40px] min-w-[40px] bg-gray-300 rounded-full"></div>
                  <div className="h-3 w-4/5 bg-gray-300 rounded-full"></div>
                </div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
              </div>
            </div>
          ))}
        </>
      )}
      {type === "singleUser" && (
        <>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              role="status"
              className="w-[100%] p-4 space-y-4 divide-y divide-gray-200 shadow animate-pulse md:p-6"
            >
              <div className="grid grid-cols-3  gap-x-10 gap-y-10 items-center justify-center">
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
              </div>
            </div>
          ))}
        </>
      )}
      {type === "table" && (
        <>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              role="status"
              className="w-[100%] p-4 space-y-4 divide-y divide-gray-200 shadow animate-pulse md:p-6"
            >
              <div className="grid grid-cols-4 gap-4 gap-x-4 gap-y-10">
                <div className="h-3 bg-gray-300 rounded-full"></div>
                <div className="h-3 bg-gray-300 rounded-full"></div>
                <div className="h-3 bg-gray-300 rounded-full"></div>
                <div className="h-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
          ))}
        </>
      )}
      {type === "topHolders" && (
        <>
          <div role="status" className="w-full space-y-4 animate-pulse">
            <div className="grid grid-cols-2 gap-4 gap-y-10">
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
              <div className="h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </>
      )}
      {type === "topClaimers" && (
        <>
          {[...Array(5)].map((_, index) => (
            <div
              role="status"
              key={index}
              className="w-full p-4 space-y-5 rounded-t-3xl animate-pulse md:p-6"
            >
              <div className="grid grid-cols-3 w-full gap-x-10 gap-y-10 items-center justify-center">
                <div className="flex items-center gap-4">
                  <div className="h-[40px] min-w-[40px] bg-gray-300 rounded-full"></div>
                  <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                </div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
                <div className="h-3 w-full bg-gray-300 rounded-full"></div>
              </div>
            </div>
          ))}
        </>
      )}
      {type === "card" && (
        <div className="flex w-full justify-between gap-4 ">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              role="status"
              className="max-w-xs w-[280px] rounded-xl p-4 border border-gray-300 rounded shadow animate-pulse"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-xl">
                <svg
                  className="w-10 h-10 text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-3 bg-gray-200 rounded-full w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full mb-3"></div>
              <div className="h-2 bg-gray-200 rounded-full mb-3"></div>
              <div className="h-2 bg-gray-200 rounded-full"></div>
              <div className="flex items-center mt-4">
                {/* <svg
                  className="w-10 h-10 me-3 text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg> */}
                <div>
                  <div className="h-3 bg-gray-200 rounded-full w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {
        type === "cardImage" && (
          <div role="status" class="flex items-center justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700">
            <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
            </svg>
          </div>

        )
      }
      {type === "token" && (
        <div className="w-[100%] ">
          <span className=" bg-gray-300 h-[185px] py-14 animate-pulse inline-block w-[100%] rounded-[18px] "></span>
        </div>
      )}
      {type === "reward" && (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              role="status"
              className="w-[100%] p-4 border border-gray-300 rounded-lg shadow animate-pulse md:p-6"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded-lg">
                {/* Placeholder Icon */}
              </div>
              <div className="h-3 bg-gray-200 rounded-full w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full mb-3"></div>
              <div className="h-2 bg-gray-200 rounded-full mb-3"></div>
              <div className="h-2 bg-gray-200 rounded-full"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-3 bg-gray-200 rounded-full w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {type === "profileImage" &&
        <div className="h-[175px] w-[175px] bg-gray-300 rounded-full shadow "></div>
      }
    </div>
  );
};

SkeletonLoading.propTypes = {
  type: PropTypes.oneOf([
    "table",
    "card",
    "token",
    "user",
    "topClaimers",
    "topHolders",
    "cardImage"
  ]).isRequired,
};

export default SkeletonLoading;
