import React from "react";

const InputField = ({ label, value, onChange, ...props }) => {
  return (
    <div className="relative w-full h-14 font-inter">
      <input
        type="text"
        className="peer h-full w-full rounded-[12px] border border-light-gray border-t-transparent bg-transparent px-3 py-2.5 !text-lg outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-light-gray placeholder-shown:border-t-light-gray focus:border-1 focus:border-light-gray focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
        placeholder=" "
        value={value}
        onChange={onChange}
        {...props}
      />
      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-black transition-all before:pointer-events-none before:mt-[7.12px] before:mr-1 before:box-border before:block before:h-[11px] before:w-2.5 before:rounded-tl-[12px] before:border-t before:border-l before:border-light-gray before:transition-all after:pointer-events-none after:mt-[7.12px] after:ml-1 after:box-border after:block after:h-[11px] after:w-2.5 after:flex-grow after:rounded-tr-[12px] after:border-t after:border-r after:border-light-gray after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.9] peer-placeholder-shown:text-light-gray peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-dark-gray peer-focus:before:border-t-1 peer-focus:before:border-l-1 peer-focus:before:border-light-gray peer-focus:after:border-t-1 peer-focus:after:border-r-1 peer-focus:after:border-light-gray peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
        {label}
      </label>
    </div>
  );
};

export default InputField;
