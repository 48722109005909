import React from 'react'
import ForgotPassSection from "../../sections/forgotPassSection/ForgotPassSection";
const ForgotPassPage = () => {
  return (
    <div>
  <ForgotPassSection />
    </div>
  )
}

export default ForgotPassPage