import React from "react";
import LoginSection from "../../sections/loginSection/LoginSection";

const LoginPage = () => {
  return (
    <>
      <LoginSection />
    </>
  );
};

export default LoginPage;
